var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "formItem",
          attrs: {
            model: _vm.formItem,
            rules: _vm.rules,
            size: "small",
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.country"),
                prop: "countryCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "350px" },
                  attrs: {
                    clearable: "",
                    placeholder: _vm.$i18n.t("common.country")
                  },
                  model: {
                    value: _vm.formItem.countryCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formItem, "countryCode", $$v)
                    },
                    expression: "formItem.countryCode"
                  }
                },
                _vm._l(_vm.dictionary.country, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Navigationcontent"),
                prop: "title",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "350px" },
                attrs: {
                  placeholder: _vm.$i18n.t(
                    "common.PleaseEnterNavigationContent"
                  )
                },
                model: {
                  value: _vm.formItem.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "title", $$v)
                  },
                  expression: "formItem.title"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.onSubmit("formItem")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }