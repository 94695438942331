<template> 
	<div>
		<el-form :model="editData" ref="titleFrom" :rules="rules" size="small" label-width="140px">

			<!--
		<el-form-item :label='$i18n.t("common.brand")'  :label-width="formLabelWidth">

				<div>
					{{editData.brandCode}}
				</div>
			</el-form-item>	

			-->
			<el-form-item :label='$i18n.t("common.country")' :label-width="formLabelWidth">

				<div>
					{{$dict.getDictValue('countrys',editData.countryCode)}}
				</div>
			</el-form-item>			
			<el-form-item :label='$i18n.t("common.SelectNavigation")' :label-width="formLabelWidth"> 
				<el-table ref="pagedata" :data="pageData.siteClassifyConfigList" border style="width: 100%">
					<el-table-column type="selection" width="55" />
					<el-table-column prop="title" :label='$i18n.t("common.name")' align="center">
						<template slot-scope="scope">
							{{scope.row.title}}
						</template>
					</el-table-column>
					<el-table-column align="center" :label='$i18n.t("common.sort")' show-overflow-tooltip>
						<template slot-scope="scope">
							<el-input v-model="scope.row.sort" style="width: 200px" type="number" @keyup.enter.native="putNaviSort(scope.row)" @blur="putNaviSort(scope.row)"></el-input>
                            <!-- <el-button
                                v-if="scope.$index != 0"
                                type="text"
                                size="medium"
                                @click="moveUp(scope.$index, scope.row)"
                                class="table-inner-button"
                                >⬆</el-button
                            >
                            <el-button
                                v-if="scope.$index != pageData.siteClassifyConfigList.length - 1"
                                type="text"
                                size="medium"
                                @click="moveDown(scope.$index, scope.row)"
                                class="table-inner-button"
                                >⬇</el-button
                            > -->
                        </template>
						<!-- <template slot-scope="scope"> -->
							<!-- <el-input @input="formListQuery.peso=formListQuery.peso.replace(/[^\d]/g,'')" placeholder="请输入内容"></el-input>	 -->					
							<!-- <el-button type="text" @click="upMove(scope.$index,scope.row)" v-if="scope.$index!==0">上移
							</el-button>
							<el-button type="text" @click="upDown(scope.$index,scope.row)"
								v-if="scope.$index!==pageData.siteClassifyConfigList.length-1">下移</el-button> -->
						<!-- </template> -->
					</el-table-column>
				</el-table>
			</el-form-item>				
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closeDialog"> {{ $i18n.t("common.cancel") }}</el-button>
			<el-button style="width: 20%;" type="primary" @click="onSubmit"> {{ $i18n.t("common.confirm") }}</el-button>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			editData:{
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		data() {
			return {
				pageData:{},
				formLabelWidth: '120px',
				activeName: '1',
				submitName: '1',
				activeName2: '1',
				activeName3: '1',
				activeName4: '1',
				synVisible: true,
				checkAll: false,
				content: '',
				editorOption: {
					placeholder: ''
				},
				isIndeterminate: true,
				siteClassifyConfigList:{
					sort:null
				},
				titleList: [{
					id: "1",
					name: this.$i18n.t("common.Spain") ,
					value: '',
				}, {
					id: "2",
					name: this.$i18n.t("common.Italy") ,
					value: '',
				}, {
					id: "3",
					name: this.$i18n.t("common.Netherlands") ,
					value: '',
				}, {
					id: "4",
					name: this.$i18n.t("common.UnitedStates") ,
					value: '',
				}, {
					id: "5",
					name: this.$i18n.t("common.France") ,
					value: '',
				}, {
					id: "6",
					name: this.$i18n.t("common.Britain") ,
					value: '',
				}, {
					id: "7",
					name: this.$i18n.t("common.Germany") ,
					value: '',
				}, {
					id: "8",
					name: this.$i18n.t("common.poland") ,
					value: '',
				}],
				languageList: [{
					id: "1",
					name: this.$i18n.t("common.English") ,
					value: '',
				}, {
					id: "2",
					name: this.$i18n.t("common.Spanish") ,
					value: '',
				}, {
					id: "3",
					name: this.$i18n.t("common.Italian") ,
					value: '',
				}, {
					id: "4",
					name: this.$i18n.t("common.Polishlanguage") ,
					value: '',
				}, {
					id: "5",
					name: this.$i18n.t("common.Dutchlanguage") ,
					value: '',
				}, {
					id: "6",
					name: this.$i18n.t("common.Portuguese"),
					value: '',
				}, {
					id: "7",
					name: this.$i18n.t("common.French") ,
					value: '',
				}, {
					id: "8",
					name: this.$i18n.t("common.German") ,
					value: '',
				}, {
					id: "9",
					name: this.$i18n.t("common.Chinese"),
					value: '',
				}],
				SeoFrom: {
					head: "",
					keyword: "",
					describe: ""
				},
				rules: {
					name: [{
						required: true,
						message: this.$i18n.t("common.Pleaseentertheproducttitle") ,
						trigger: 'blur'
					}]
				}
			};
		},
		methods: {
			initData(){
				this.getNaviClass()						
			},
			putNaviSort(row){
				const query = {
					id:row.siteClassifyConfigId,
					sort:row.sort
				}
				this.$store
					.dispatch('putNaviSort', query)
					.then(res => {
						this.getNaviClass()		
				});
			},
			moveUp(index, row) {
            console.log(index);
            if (index > 0) {
                let old = this.pageData.siteClassifyConfigList[index - 1];
                this.pageData.siteClassifyConfigList.splice(index - 1, 1);
                this.pageData.siteClassifyConfigList.splice(index, 0, old);
            } else {
                this.$message({
                    message:this.$i18n.t("common.Thisisalreadythefirstitemmoveupfailed"),
                    type: 'warning'
                });
            }
            console.log(this.pageData.siteClassifyConfigList);
        },
        moveDown(index, row) {
			console.log(index);
            if (index + 1 == this.pageData.siteClassifyConfigList.length) {
                this.$message({
					message:this.$i18n.t("common.Thisisalreadythelastitemmovedownfailed"),
                    type: 'warning'
                });
            } else {
                let old = this.pageData.siteClassifyConfigList[index + 1];
                this.pageData.siteClassifyConfigList.splice(index + 1, 1);
                this.pageData.siteClassifyConfigList.splice(index, 0, old);
            }
        },
			// //上移
			// upMove(index, row) {
			// 	if (index <= 0) {
			// 		this.$message.error('不能继续向上移动')
			// 	} else {
			// 		let sort=this.pageData.siteClassifyConfigList[index].sort
			// 		let sort1=this.pageData.siteClassifyConfigList[index - 1].sort
			// 		if(sort==sort1){
			// 			this.pageData.siteClassifyConfigList[index].sort=this.pageData.siteClassifyConfigList[index].sort-1
			// 		}else{
			// 			this.pageData.siteClassifyConfigList[index].sort=sort1
			// 			this.pageData.siteClassifyConfigList[index-1].sort=sort
			// 			// const upData = this.pageData.siteClassifyConfigList[index - 1]
			// 			// this.pageData.siteClassifyConfigList.splice(index - 1, 1)
			// 			// this.pageData.siteClassifyConfigList.splice(index, 0, upData)
			// 			// this.pageData.siteClassifyConfigList[index].sort=upData.sort					
			// 		}
			// 		this.pageData.siteClassifyConfigList.sort((a,b)=>{
			// 			return a.sort+b.sort
			// 		})
			// 		console.log('222',this.pageData)
			// 	}
			// },
			// //下移
			// upDown(index, scope) {
			// 	console.log('1', scope)
			// 	if (index === (this.pageData.siteClassifyConfigList.length - 1)) {
			// 		this.$message.error('不能继续向下移动')
			// 	} else {
			// 		let sort=this.pageData.siteClassifyConfigList[index].sort
			// 		let sort1=this.pageData.siteClassifyConfigList[index + 1].sort
			// 		if(sort==sort1){
			// 			this.pageData.siteClassifyConfigList[index].sort=this.pageData.siteClassifyConfigList[index].sort+1
			// 		}else{
			// 			this.pageData.siteClassifyConfigList[index].sort=sort1
			// 			this.pageData.siteClassifyConfigList[index+1].sort=sort							
			// 		}
			// 		this.pageData.siteClassifyConfigList.sort((a,b)=>{
			// 			return a.sort+b.sort
			// 		})
			// 		console.log('333',this.pageData)					
			// 	}
			// },
			getNaviClass(){
				this.$store
					.dispatch('getNaviClass', {
						id:this.editData.siteNavigationId
					})
					.then(res => {
						res.siteClassifyConfigList.sort((a,b)=>{
							return a.sort+b.sort
						})				
						this.pageData=res
						this.$nextTick(()=>{
							//不能全选中
							 res.siteClassifyConfigList.forEach(element => {
								if(element.status===true){
									this.$refs.pagedata.toggleRowSelection(element)
								}
							 });
						})	
						console.log('this.listQuery',this.pageData)    			
				});
			},
			closeDialog(){
				this.$emit('closeDialog')
			},
			onSubmit() {
				if(this.$refs.pagedata.selection.length<=0){					
					this.$message({
						message:this.$i18n.t("common.Pleasecheckfirst"),
					    type: 'warning'      
					});
					return
				}
				
				//对siteClassifyConfigList遍历
				var dateList = JSON.parse(JSON.stringify(this.pageData.siteClassifyConfigList)) 
				var selectDate = this.$refs.pagedata.selection;
				for(var i=0;i<dateList.length;i++){
					dateList[i].status = false;
					// dateList[i].sort = i + 1;
					selectDate.forEach(e=>{
						if(e.siteClassifyConfigId==dateList[i].siteClassifyConfigId){
							dateList[i].status = true;
						}
					})	
				}
				this.$store
					.dispatch('putNaviClass', {
						id:this.editData.siteNavigationId,
						// siteClassifyConfigList:this.$refs.pagedata.selection
						siteClassifyConfigList: dateList
					})
					.then(res => {
						message:this.$i18n.t("common.Successfullysubmitted"),
						this.closeDialog()						
						console.log('this.listQuery',this.pageData)    			
				});
			}
		},
		mounted(){
			this.initData()
		}
	};
</script>
<style scoped>
	.input-width {
		width: 70%;
	}

	.dialog-footer {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.tree-container {
		border: 1px solid #EBEEF5;
		padding: 20px 10px;
		min-height: 400px;
		overflow-y: scroll;
	}
</style>
