var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", "min-height": "350px" } },
    [
      _c("filter-container", {
        attrs: {
          arg: _vm.arg,
          defaultListQuery: _vm.defaultListQuery,
          placeholder: _vm.placeholder,
          dictionary: _vm.dictionary
        },
        on: {
          handleSearchList: _vm.handleSearchList,
          handleResetSearch: _vm.handleResetSearch
        }
      }),
      _c(
        "div",
        { staticStyle: { margin: "10px 0px 10px 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: { click: _vm.add }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.HomepageConfiguration")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: { click: _vm.addSite }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.Add1")) + " ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "tableData",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.pageData.list,
            height: "calc(100% - 170px)",
            border: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "siteNavigationId",
              label: "ID",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCode",
              label: _vm.$i18n.t("common.country"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.$dict.getDictValue(
                            "countrys",
                            scope.row.countryCode
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$i18n.t("common.Navigationcontent"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$i18n.t("common.Creationtime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.edit(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.edit")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.del(scope.row)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$i18n.t("common.delete")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.limit,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.HomepageConfiguration"),
            visible: _vm.dialogConfigFormVisible,
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogConfigFormVisible = $event
            }
          }
        },
        [
          _vm.dialogConfigFormVisible
            ? _c(
                "div",
                [
                  _c("config-dialog", {
                    attrs: { langList: _vm.languageList },
                    on: {
                      closeDialog: _vm.closeDialog,
                      handleSearchList: _vm.handleSearchList
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.edit"),
            visible: _vm.dialogFormVisible,
            "before-close": _vm.closeDialog2,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _vm.dialogFormVisible
            ? _c(
                "div",
                [
                  _c("update-dialog", {
                    attrs: { editData: _vm.editData },
                    on: { closeDialog: _vm.closeDialog2 }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.WebsiteNavigationConfiguration"),
            width: "600px",
            visible: _vm.dialogAddVisible,
            "before-close": _vm.closeDialogAdd,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogAddVisible = $event
            }
          }
        },
        [
          _vm.dialogAddVisible
            ? _c(
                "div",
                [
                  _c("add-dialog", {
                    attrs: { dictionary: _vm.dictionary },
                    on: { closeDialog: _vm.closeDialogAdd }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }