<template> 
	<div>
		<el-form :model="formItem" ref="formItem" :rules="rules" size="small" label-width="140px">

 
			<el-form-item :label='$i18n.t("common.country")' prop="countryCode" :label-width="formLabelWidth">
				<el-select v-model="formItem.countryCode" clearable class="filter-item" style="width: 350px; " :placeholder='$i18n.t("common.country")'>
						<el-option
							v-for="item in dictionary.country"
							:key="item.id"
							:label="item.name"	
							:value="item.code">
						</el-option>      
					</el-select>
			 
			</el-form-item>		
			
			<el-form-item :label='$i18n.t("common.Navigationcontent")' prop="title" :label-width="formLabelWidth">
				<el-input v-model="formItem.title" style="width: 350px;" :placeholder='$i18n.t("common.PleaseEnterNavigationContent")'   ></el-input>			 
			</el-form-item>		

			 			
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closeDialog"> {{ $i18n.t("common.cancel") }}</el-button>
			<el-button style="width: 20%;" type="primary" :loading="loading" @click="onSubmit('formItem')"> {{ $i18n.t("common.confirm") }}</el-button>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			dictionary:{
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		data() {
			return {
				loading: false,				 
				formLabelWidth: '120px',
				formItem:{
					countryCode: '',
					title: '',
				},
				rules: {
					countryCode: [{
						required: true,
						message: this.$i18n.t("common.Pleaseselectacountry") ,
						trigger: 'change'
					}],
					title: [{
						required: true,
						message: this.$i18n.t("common.PleaseEnterNavigationContent") ,
						trigger: 'blur'
					}]
				}
			};
		},
		mounted(){
			 
		},
		methods: {
			 
			closeDialog(){
				this.$emit('closeDialog')
			},
 
			onSubmit(formName) {
				 
				this.$refs[formName].validate(valid => {
               
					if (valid) {
 
						this.$confirm(this.$i18n.t("common.Whethertosubmitdata"),this.$i18n.t("common.prompt"), {
							// confirmButtonText: '确定',
							// cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {   
							this.loading=true
						
							this.$store.dispatch('addNavi', this.formItem).then(response => {
								this.$message({
									message: this.$i18n.t("common.Successfullysubmitted"),
									type: 'success',
									duration: 1000
								});
								this.closeDialog();
							}).finally(() => {
								this.loading = false;
							});
							 
						}).catch(() => {});
		 				
					} else {
						this.$message({
							message:  this.$i18n.t("common.Verificationfailed"),
							type: 'error',
							duration: 1000
						});
						return false;
					}
            	});
			}
		},
		
	};
</script>
<style scoped>
	.input-width {
		width: 70%;
	}

	.dialog-footer {
		
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.tree-container {
		border: 1px solid #EBEEF5;
		padding: 20px 10px;
		min-height: 400px;
		overflow-y: scroll;
	}

</style>
